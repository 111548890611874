<template>
  <div class="modify">
    <el-form
      :inline-message="true"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      ref="ruleForm"
    >
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile" disabled></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="password">
        <el-input type="password" v-model="ruleForm.password"></el-input>
      </el-form-item>
      <p class="modify-text">建议设置密码为6-12位,数字、字母、特殊字符任意至少两种的组合</p>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="ruleForm.confirmPassword"></el-input>
      </el-form-item>
      <el-form-item label="手机验证码" prop="verifyCode">
        <el-input class="verifyCode" v-model="ruleForm.verifyCode"></el-input>
        <!-- <el-button :disabled="isClick" @click="getCode" class="modify__btn--code" type="text">{{
          tips
        }}</el-button> -->
        <getVerificationCode :mobile="ruleForm.mobile" class="modify__btn--code" />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="saveLoading"
          @click="submitForm('ruleForm')"
          class="modify__btn--save"
          type="primary"
          >确 定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
//import { getCode } from '@/api/system/user';
import { validatePhoneSimple } from '@/common/validator';
import { forgetPassword } from '@/api/login';
import { mapActions } from 'vuex';
import { getAuthenticateInfo } from '@/api/step';
import getVerificationCode from '@/components/getVerificationCode';
import md5 from 'js-md5';
export default {
  name: 'modify',
  components: { getVerificationCode },
  data() {
    const passwordCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        mobile: '',
        password: '',
        confirmPassword: '',
        verifyCode: '',
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhoneSimple, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' },
        ],
        confirmPassword: [{ required: true, validator: passwordCheck, trigger: 'blur' }],
        verifyCode: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      tips: '获取验证码',
      isClick: false,
      saveLoading: false,
    };
  },
  created() {
    this.getMobile();
  },
  methods: {
    ...mapActions(['GetAuthenticateInfo']),
    getMobile() {
      getAuthenticateInfo().then(res => {
        this.ruleForm.mobile = res.data.mobile;
      });
    },
    submitForm(formName) {
      const postData = {
        ...this.ruleForm,
        password: md5(this.ruleForm.password),
        confirmPassword: md5(this.ruleForm.confirmPassword),
      };
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveLoading = true;
          forgetPassword(postData)
            .then(res => {
              if (res.code === '0') {
                const data = {
                  username: this.ruleForm.mobile,
                  password: this.ruleForm.password,
                };
                this.$store
                  .dispatch('Login', data)
                  .then(loginRes => {
                    if (loginRes === 'DISABLE') {
                      this.$confirm('您的账户已冻结，请联系您的专属顾问。', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true,
                      })
                        .then(() => {})
                        .catch(() => {});
                    } else {
                      return this.GetAuthenticateInfo();
                    }
                  })
                  .then(res => {
                    this.$router.push('/syoung');
                  });
              }
            })
            .finally(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // getCode() {
    //   if (!this.ruleForm.mobile || !/^1\d{10}$/.test(this.ruleForm.mobile)) {
    //     this.$message.error('请正确输入手机号');
    //     return;
    //   }
    //   this.isClick = true;
    //   let sec = 59;
    //   this.t = setInterval(() => {
    //     this.tips = `${sec}s`;
    //     if (!sec || sec < 1) {
    //       clearInterval(this.t);
    //       this.tips = '获取验证码';
    //       this.isClick = false;
    //     }
    //     sec--;
    //   }, 1000);
    //   getCode(this.ruleForm.mobile).then(response => {
    //     this.$message.success('发送成功');
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.modify {
  padding-top: 136px;
  &-text {
    padding-left: 100px;
    margin-bottom: 10px;
  }
  .el-form {
    width: 648px;
    margin: 0 auto;
    .el-input {
      width: 350px;
    }
    .modify-btn-save {
      width: 250px;
      margin-left: 100px;
    }
  }

  &__btn {
    &--code {
      color: #9d9d9d;
      font-size: 18px;
      position: absolute;
      right: 208px;
      top: 0;
      width: 100px;
      height: 36px;
      line-height: 36px;
      padding: 0;
      text-align: center;
    }
    &--save {
      background: #AB0033;
      border-radius: 5px;
      font-size: 18px;
      border-color: #AB0033;
      width: 250px;
      color: #fff;
      margin: 30px 0 0 50px;
    }
  }
}
</style>